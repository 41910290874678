import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import Image from 'next/image'
import regularDesktop from './images/regular-desktop.svg'
import regularMobile from './images/regular-mobile.svg'
import stickyDesktop from './images/sticky-desktop.svg'
import { useMediaQuery } from '@material-ui/core'
import { useRouter } from 'next/router'

export type CreditUnionLogoProps = {
  notext?: boolean
}

export const StyledLogoContainer = styled.figure`
  margin: 0;
  display: flex;
  position: relative;
`

type LogoVariant = 'home-desktop' | 'regular-desktop' | 'regular-mobile' | 'sticky-desktop' | 'sticky-mobile'

const logoMap: Record<LogoVariant, string> = {
  'home-desktop' : regularDesktop,
  'regular-desktop': regularDesktop,
  'regular-mobile': regularMobile,
  'sticky-desktop': stickyDesktop,
  'sticky-mobile': stickyDesktop,
}
const sizeMap: Record<LogoVariant, [number, number]> = {
  'home-desktop' : [147, 70],
  'regular-desktop': [117, 56],
  'regular-mobile': [99, 48],
  'sticky-desktop': [41, 54],
  'sticky-mobile': [30, 40],
}

export const CreditUnionLogo: React.FC<CreditUnionLogoProps> = ({ notext, ...props }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const router = useRouter()

  const variant: LogoVariant = useMemo(() => {
    if (isDesktop && router.route === '/') {
      return notext ? 'sticky-desktop' : 'home-desktop'
    } else if (isDesktop) {
      return notext ? 'sticky-desktop' : 'regular-desktop'
    } else {
      return notext ? 'sticky-mobile' : 'regular-mobile'
    }
  }, [notext, isDesktop])
  const src = useMemo(() => logoMap[variant], [variant])
  const [width, height] = useMemo(() => sizeMap[variant], [variant])

  return (
    <StyledLogoContainer>
      <Image priority src={src} width={width} height={height} { ...props } alt={'logo'} />
    </StyledLogoContainer>
  )
}

export const StyledCreditUnionLogo = styled(CreditUnionLogo)`
  transition: all 0.2s ease;
`
